import React from "react";
import Link from "gatsby-link";

import { Jumbotron, Grid, Row, Col, Image, Button } from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import Header from "../../components/header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout";
import JobHeader from "../../components/job-header.js";
const PerfumeShopPage = () => (
  <Layout>
    <JobHeader
      image="/img/perfumeshop-header.jpg"
      title="The Perfume Shop"
      subtitle="Instore digital POS and online support"
      text="Digital POS / Animation / After Effects / Web Design"
    />

    <Row className="show-grid side-padding intro">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>My Role</h2>
        <p>
          <em>
            My role at A.S Watson involves supporting the Business Units with
            their online activities. Thise includes supporting The Perfume Shop,
            helping them to create compelling animated POS for their instore
            digital screens. The Perfume Shop have a team of internal print
            designers that work on the designs and then send to me to animmate.
            You can see some examples below.
          </em>
        </p>
      </Col>
    </Row>

    <Row className="show-grid pt40">
      <Col xs={12} md={4}>
        <video
          width="100%"
          height="100%"
          src="/img/perfume-shop/boss-uk2.mp4"
          controls
        />
      </Col>
      <Col xs={12} md={4}>
        <video
          width="100%"
          height="100%"
          src="/img/perfume-shop/half-term2.mp4"
          controls
        />
      </Col>
      <Col xs={12} md={4}>
        <video
          width="100%"
          height="100%"
          src="/img/perfume-shop/selected-UK2.mp4"
          controls
        />
      </Col>
    </Row>

    <Row className="show-grid pt40">
      <Col xs={12} md={4}>
        <video
          width="100%"
          height="100%"
          src="/img/perfume-shop/always-fathers2.mp4"
          controls
        />
      </Col>
      <Col xs={12} md={4}>
        <video
          width="100%"
          height="100%"
          src="/img/perfume-shop/armani-uk-v3.mp4"
          controls
        />
      </Col>
      <Col xs={12} md={4}>
        <video
          width="100%"
          height="100%"
          src="/img/perfume-shop/easter-boss2.mp4"
          controls
        />
      </Col>
    </Row>

    <UpNext
      image1="/img/marionnaud-card2.jpg"
      title1="Marionnaud"
      text1="A European Health & Beauty brand"
      url1="/marionnaud/"
      image2="/img/perfumeshop-card.jpg"
      title2="The Perfume Shop"
      text2="A fragrance specialist based in the UK"
      url2="/perfume-shop/"
      image3="/img/spread-co-card.jpg"
      title3="Spread Co"
      text3="A financial spread betting company"
      url3="/spread-co/"
    />
  </Layout>
);

export default PerfumeShopPage;
